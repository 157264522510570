<template>
    <div class="flex flex-col-reverse lg:flex-row relative">
        <div class="lg:w-3/4">
            <article id="faqs">
                <h2>Frequently Asked Questions</h2>
                <ul>
                    <li>
                        If you have a question or need technical support, you
                        can call: <b>(206)219-1246</b>
                    </li>
                    <li>
                        From within the Midwinter virtual meeting platform, you
                        can click on the ‘Live Chat’ icon in the bottom right
                        corner and start a chat session
                    </li>
                    <li>
                        Live support via phone and chat are available these
                        hours:
                        <ul>
                            <li>
                                <b>Monday, 1/11 - 12:00 p.m. - 5:00 p.m.</b>
                            </li>
                            <li>
                                <b>Tuesday, 1/12 - 2:00 p.m. - 5:00 p.m.</b>
                            </li>
                            <li>
                                <b>Thursday, 1/21 - 10:00 a.m. - 5:00 p.m.</b>
                            </li>
                            <li>
                                <b>Friday, 1/22 - 10:00 a.m. - 3:00 p.m.</b>
                            </li>
                            <li>
                                <b>Monday, 1/25 - 9:00 a.m. - 2:30 p.m. </b>
                            </li>
                            <li>
                                <b>Tuesday, 1/26 - 9:00 a.m. - 3:00 p.m.</b>
                            </li>
                            <li>
                                <b>Wednesday, 1/27 - 9:00 a.m. - 6:00 p.m.</b>
                            </li>
                            <li>
                                <b>Thursday, 1/28 - 9:00 a.m. - 6:00 p.m.</b>
                            </li>
                            <li>
                                <b>Friday, 1/29 - 9:00 a.m. - 6:00 p.m.</b>
                            </li>
                            <li>
                                <b>Monday, 2/1 - 9:00 a.m. - 6:00 p.m.</b>
                            </li>
                            <li>
                                <b>Tuesday, 2/2 - 9:00 a.m. - 6:00 p.m.</b>
                            </li>
                            <li>
                                <b>Wednesday, 2/3 - 9:00 a.m. - 6:00 p.m.</b>
                            </li>
                            <li>
                                <b>Thursday, 2/4 - 9:00 a.m. - 6:00 p.m.</b>
                            </li>
                        </ul>
                    </li>
                    <li>
                        The technical support line and live chat will be staffed
                        by FMI and event staff to assist with logins, meeting
                        access, and technical challenges.
                    </li>
                    <li>
                        Email us at
                        <a
                            href="mailto:midwinterinfo@fmi.org"
                            target="_blank"
                            rel="noopener noreferrer"
                            >midwinterinfo@fmi.org</a
                        >
                        at any time
                    </li>
                    <!-- <li>
                        <a
                            href="https://us02web.zoom.us/rec/share/bYVJ36yIst_jygdR13SgPLf_uOmTvV3kKtZPXWkdryYKS75q2WXLTWMMwMyxzHE.3VleFQr5OTaLhklz"
                            target="_blank"
                            rel="noopener noreferrer"
                            >A training tutorial for the platform is
                            available.</a
                        >
                        Access Passcode: QG3Aj7?3
                    </li> -->
                </ul>
            </article>

            <article id="login" class="mt-8">
                <h2>Logging Into Midwinter</h2>
                <p>
                    Since you are accessing these FAQ’s from within the
                    Midwinter virtual meeting, we assume you were able to log in
                    successfully.
                </p>
                <ul>
                    <li>
                        <b>Can someone else log in for me?</b><br />
                        No, your login information is specific to you and should
                        be kept private. For security purposes, you can only be
                        logged in from one location at a time.
                    </li>
                    <li>
                        <b
                            >What equipment and technical specifications are
                            needed to access Midwinter and participate in the
                            private meetings?</b
                        ><br />
                        If you can access the web, you can participate! However,
                        to be successful we strongly encourage using a PC or Mac
                        with a keyboard, camera, microphone, and strong internet
                        connection.<br />
                        <br />
                        In general, here are the specific requirements:
                        <ul>
                            <li>
                                PC, Mac, tablet, or smartphone (PC or Mac is
                                highly recommended)
                            </li>
                            <li>
                                Internet connection (wired is better than wi-fi)
                            </li>
                            <li>
                                Modern web browser. Midwinter thrives in the
                                latest versions of Chrome, Safari and Edge. IE11
                                is not fully supported and you should use a
                                different browser.
                            </li>
                            <li>
                                <b
                                    >Please upgrade to the latest version of
                                    your chosen web browser. Although our system
                                    supports several versions of Chrome and
                                    Firefox, we do not support older versions of
                                    Edge or Internet Explorer.</b
                                >
                            </li>
                            <li>
                                <b
                                    >If using Microsoft Edge, please make sure
                                    you have the latest version. You can
                                    <a
                                        href="https://www.microsoft.com/en-us/edge?form=MY01BL&OCID=MY01BL"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        >download it here.</a
                                    ></b
                                >
                            </li>
                            <li>
                                If you plan to participate from a tablet or
                                smartphone, there are certain features that will
                                not be enabled for you, including screen sharing
                                and chat in the private meetings.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <b
                            >Can I access the platform before the event begins
                            to confirm my equipment will work?</b
                        >
                        <p>
                            Yes, and we encourage this from all participants.
                            Each SEEs participating company has a Private
                            Meeting Room available each day from 9:00am – 6:00pm
                            eastern which is accessible from the
                            <router-link to="/schedule"
                                >‘My Schedule’</router-link
                            >
                            tab. You can test your equipment, explore the SEEs
                            room features and screen sharing capabilities, meet
                            with your colleagues for discussions, or practice a
                            presentation.
                        </p>
                        <p>
                            The virtual video help desk is available to all
                            attendees each conference day during peak times and
                            can be accessed from the AGENDA tab. The help desk
                            is staffed by platform professionals to assist with
                            technology needs. Feel free to visit as often as
                            needed, but note: the room is not private and you
                            should not share sensitive materials.
                        </p>

                        <b>Please note: </b>the Test Meeting room is open to ALL
                        attendees during this time. It is <u>not</u> a private
                        room.
                    </li>
                    <!-- <li>
                        <b>How do I sign up for notifications?</b><br />
                        You can sign up for notifications by clicking on the
                        “bell” icon on the bottom left hand corner of the
                        screen. Notifications can be sent to desktop browsers or
                        mobile devices.
                    </li> -->
                </ul>
            </article>

            <article id="meetings" class="mt-8">
                <h2>Scheduled Meetings</h2>
                <ul>
                    <li>
                        <b>In what time zone are the meetings scheduled?</b
                        ><br />
                        The official time zone of the Midwinter Conference is
                        Eastern Time. However, the virtual platform should
                        adjust to your local time zone based on your computer
                        time.
                    </li>
                    <li>
                        <b>Where do I see my confirmed meetings?</b><br />
                        You can see and access your confirmed meetings in the
                        <router-link to="/schedule">‘My Schedule’</router-link>
                        tab at the top of the screen
                    </li>
                    <li>
                        <b>How do I access my private meetings?</b><br />
                        Click on the
                        <router-link to="/schedule">‘My Schedule’</router-link>
                        tab in the top menu bar. Your meetings will be displayed
                        within the calendar. The calendar will open to the
                        current upcoming date.
                    </li>
                    <li>
                        <b
                            >Why does my schedule include all my company’s
                            meetings – do I need to attend all of them?</b
                        ><br />
                        Your schedule will show all meetings that have been
                        scheduled by your company; you only need to attend the
                        meetings you were designated to attend.
                    </li>
                    <li>
                        <b
                            >What features are available in the private
                            meetings?</b
                        >
                        <ul>
                            <li>Video and presentation sharing</li>
                            <li>Webcam and audio</li>
                            <li>Share your screen</li>
                            <li>Mute/unmute yourself</li>
                            <li>Chat with all attendees</li>
                            <li>Grid or speaker views</li>
                            <li>
                                Adjust your settings to switch your camera or
                                audio input/output
                            </li>
                        </ul>
                    </li>
                    <li>
                        <b
                            >How soon before the scheduled start time can I
                            access the private meeting room?</b
                        ><br />
                        Suppliers can access the private meeting room 15 minutes
                        prior to the scheduled meeting start time. Retailers can
                        access the private meeting rooms 5 minutes prior to the
                        start time.
                    </li>
                    <li>
                        <b
                            >Can suppliers do a rehearsal prior to the meeting
                            if presenting?</b
                        ><br />
                        Suppliers can access their meeting room 15 minutes prior
                        to the scheduled time to rehearse.
                    </li>
                    <li>
                        <b
                            >Can we continue the meeting past the scheduled stop
                            time?</b
                        ><br />
                        There is a 10-minute grace period after the 45-minute
                        meeting. After 10 minutes, the meeting will
                        automatically end, and participants will not be able to
                        re-enter the room.
                    </li>
                    <li>
                        <b>What if I become disconnected from the meeting?</b
                        ><br />
                        Return to the
                        <router-link to="/schedule">‘My Schedule’</router-link>
                        page and click on the meeting link again. If you are
                        having problems joining the meeting, please click on the
                        “HELP” tab in the top menu bar or on the “Live Chat”
                        icon in the bottom right hand corner of your screen.
                    </li>
                    <li>
                        How do we prepare for the meeting?
                        <ul>
                            <li>
                                Log in to the site to make sure your meetings
                                are correct. If anything is missing or
                                incorrect, please contact Marc Thomas at
                                <a href="mailto:mthomas@fmi.org"
                                    >mthomas@fmi.org</a
                                >
                            </li>
                            <li>
                                Read the “Best Practices for Meeting
                                Participation” below
                            </li>
                        </ul>
                    </li>
                    <li>
                        <b
                            >What if the private meeting does not recognize my
                            preferred speaker and camera?</b
                        >
                        <ul>
                            <li>
                                If you have multiple speakers, microphones, and
                                cameras, you will need to tell the private
                                meeting rooms about your preferences.
                            </li>
                            <li>
                                Click on the “Gear” icon in the lower right
                                corner of the private meeting rooms.
                            </li>
                            <li>
                                Select Devices, then select your preferred
                                speaker, microphone, and camera.
                            </li>
                            <li>
                                Your preferences will be saved on that browser.
                                If you log in from another browser, you may need
                                to select your Device options again.
                            </li>
                        </ul>
                    </li>
                </ul>
            </article>
            <article class="mt-4">
                <h2>Direct Connect</h2>
                <p>
                    Direct Connect opens for meeting scheduling on Monday, Jan
                    25 and is available throughout the official conference days
                    only.
                </p>
                <p class="text-lg">The hours meetings can be scheduled are:</p>
                <ul>
                    <li>Jan 25-29 - 12pm - 6pm and</li>
                    <li>Feb 1 - 4, 10am - 6pm.</li>
                    <li>
                        The
                        <router-link to="/attendees">"ATTENDEES"</router-link>
                        tab lets you search for attendees so you can schedule a
                        meeting.
                    </li>
                    <li>
                        You can search for other attendees by first name, last
                        name, and organization.
                    </li>
                    <li>
                        Once you find the person you are looking for, click
                        schedule a meeting.
                    </li>
                    <li>
                        You will be able to invite up to 1 person for each
                        appointment.
                    </li>
                    <li>
                        Appointments are available on your
                        <router-link to="/schedule">‘My Schedule’</router-link>
                        page and will be held in a video room.
                    </li>
                    <li>
                        After you create the invitation, the people invited
                        will.
                        <ul>
                            <li>
                                See a message in the message center (if they are
                                not on the page, they will see a small number
                                next to the Message Center tab showing that
                                there is a message)
                            </li>
                            <li>
                                See the invitation on their
                                <router-link to="/schedule"
                                    >‘My Schedule’</router-link
                                >
                                page.
                            </li>
                            <li>
                                Invitees can accept or decline (on My Schedule);
                                you will receive a message/email with the
                                acceptance message or the decline.
                            </li>
                            <li>
                                Invitees can send you a follow up message,
                                asking to move the time or for you to add people
                                to the meeting invitation
                            </li>
                            <li>As the host, you can</li>
                                <ul>
                                    <li>Cancel the meeting invitation</li>
                                </ul>
                        </ul>
                    </li>
                </ul>
            </article>
            <article id="sponsors" class="mt-10">
                <h2>Sponsors</h2>

                <ul>
                    <li>
                        <b>Where can I find sponsor information?</b><br />
                        Click on the
                        <router-link to="/tradeshow">“SPONSORS”</router-link>
                        tab in the top menu bar to view sponsor information and
                        connect with sponsors.
                    </li>
                </ul>
            </article>

            <article id="particpants" class="mt-8">
                <h2>Communicating With Other Participants</h2>

                <ul>
                    <li>
                        <b>Where can I see who is attending?</b><br />
                        Click on the
                        <router-link to="/attendees">"ATTENDEES"</router-link>
                        tab on the top menu bar. You will see a random selection
                        of participants and can search for other participants by
                        first name, last name, or company. A full, sortable list
                        can also be found on the Midwinter website.
                    </li>
                    <li>
                        <b>Are the private meetings being recorded?</b><br />
                        No, neither the meeting itself, nor the chat messages
                        are recorded.
                    </li>
                    <li>
                        <b
                            >Can I get a transcript of the chat in the private
                            meeting rooms?</b
                        >
                        No, the chat messages are not recorded.
                    </li>
                    <li>
                        <b>Can I contact or message other attendees?</b><br />
                        Yes, you can search for an attendee by first name, last
                        name, or company under the “ATTENDEES” tab on the top
                        menu bar. Then click on the “Send a Message” button.
                    </li>
                </ul>
            </article>

            <article id="troubleshooting" class="mt-8">
                <h2>Troubleshooting</h2>

                <ul>
                    <li>
                        <b
                            >How do I get help if I am having technical
                            issues?</b
                        >
                        There are several ways to get help if you are having
                        issues with the site.
                        <ol>
                            <li>Click on the “HELP” tab on the top menu bar</li>
                            <li>
                                Click on the help icon on the bottom right
                                corner or your screen to chat live with a
                                support person
                            </li>
                            <li>
                                <a href="tel:(206) 219-1246"
                                    >Call (206) 219-1246</a
                                >
                            </li>
                            <li>
                                Email us
                                <a href="mailto:midwinterinfo@fmi.org"
                                    >midwinterinfo@fmi.org</a
                                >
                            </li>
                            <li>
                                Live technical support is available every day of
                                the conference from 9:00am - 6:00pm EST
                            </li>
                        </ol>
                    </li>
                    <li>
                        What should I do if...
                        <ul>
                            <li>
                                <b
                                    >My scheduled meeting is not showing up on
                                    my schedule?</b
                                >
                                Contact Marc Thomas at
                                <a href="mailto:mthomas@fmi.org"
                                    >mthomas@fmi.org</a
                                >
                                or email the Midwinter help desk at
                                <a href="mailto:midwinterinfo@fmi.org"
                                    >midwinterinfo@fmi.org</a
                                >
                            </li>

                            <li>
                                <b>I cannot access a private meeting?</b> Please
                                click on the “HELP” tab on the top menu bar or
                                click on the help icon on the bottom right of
                                your screen to chat live or call the helpline
                                <a href="tel:(206) 219-1246">(206) 219-1246</a>
                            </li>

                            <li>
                                <b
                                    >I can’t hear/see other attendees in a
                                    private meeting or they can’t hear/see
                                    me?</b
                                >
                                Please click on the “HELP” tab on the top menu
                                bar or click on the help icon on the bottom
                                right of your screen to chat live or call the
                                helpline
                                <a href="tel:(206) 219-1246">(206) 219-1246</a>.
                            </li>

                            <li>
                                <b>My audio/video is buffering.</b>
                                <ol>
                                    <li>
                                        Check the speed on your internet
                                        connection
                                    </li>
                                    <li>
                                        Try turning off your camera. A camera
                                        can consume a lot of bandwidth
                                    </li>
                                    <li>
                                        Close all other applications running on
                                        your device.
                                    </li>
                                    <li>
                                        Make sure that your device is as close
                                        to the router as possible. If possible,
                                        use a hard-wired connection
                                    </li>
                                    <li>
                                        Click on the “Help” tab on the top menu
                                        bar or on the help icon on the bottom
                                        right of your screen to chat live.
                                    </li>
                                </ol>
                            </li>

                            <li>
                                <b
                                    >I hear an echo when I talk or from other
                                    attendees.</b
                                >
                                <ol>
                                    <li>Try muting and then unmuting.</li>
                                    <li>
                                        If possible, do not use the built-in
                                        microphone and speakers on your laptop.
                                        Use an external headset or speaker.
                                    </li>
                                </ol>
                            </li>
                        </ul>
                    </li>
                </ul>
            </article>

            <article id="practices" class="mt-8">
                <h2>Best Practices For Meeting Participation</h2>

                To ensure that your participation in Midwinter is as successful
                as possible, we recommend the following best practices:
                <ul>
                    <li>
                        <b>Equipment</b>
                        <ul>
                            <li>
                                General requirements
                                <ul>
                                    <li>
                                        You will need access to either a PC,
                                        Mac, tablet, or smartphone. A PC or Mac
                                        is recommended. To participate in the
                                        meetings, it is recommended that you
                                        have an internal or external camera so
                                        that you can be seen on video.
                                    </li>
                                </ul>
                            </li>

                            <li>
                                Microphones<br />
                                Clear audio can make a big difference.
                                Laptop/phone audio can work, but audio through
                                an external microphone (connected by wire or
                                Bluetooth) can be a huge plus. We recommend:
                                <ul>
                                    <li>
                                        Logitech USB Headset H390 with Noise
                                        Cancelling Mic
                                    </li>
                                    <li>
                                        Avid Education 1EDU-AE36WH-ITE Headphone
                                        with Boom Microphone, Single Plug, White
                                    </li>
                                </ul>
                            </li>

                            <li>
                                Browser
                                <ul>
                                    <li>
                                        Midwinter will support the latest
                                        versions of Chrome, Safari, Firefox, and
                                        Edge. IE11 is not fully supported and
                                        you should use a different browser.
                                    </li>
                                </ul>
                            </li>

                            <li>
                                Internet Connection Tips
                                <ul>
                                    <li>
                                        general, wired connections are better
                                        than wireless connections, and wireless
                                        connections are better than cellular
                                        based connections.
                                    </li>
                                </ul>
                            </li>

                            <li>
                                While Midwinter supports tablets and
                                smartphones, we recommend using a PC or Mac as
                                some functions will not work on a tablet or
                                phone.
                            </li>
                        </ul>
                    </li>

                    <li>
                        <b>Your Surroundings</b>
                        <ul>
                            <li>
                                Find a quiet area for participation. Avoid areas
                                with loud background noise (traffic, wind, loud
                                vents, or appliances).
                            </li>
                            <li>
                                Be one with the light and find the balance! Try
                                to avoid a dark room. Natural room sunlight is
                                good – just do not stand
                            </li>
                            <li>with the light shining right behind you.</li>
                            <li>
                                Consider the acoustics of the room. A small room
                                with dead acoustics will not flatter a voice.
                            </li>
                            <li>
                                Position yourself relatively close to the camera
                            </li>
                            <li>
                                Frame the camera so that your eyes are in the
                                top third of the frame.
                            </li>
                            <li>Use a plain, uncluttered background.</li>
                        </ul>
                    </li>

                    <li>
                        <b>Screen Sharing</b>
                        <ul>
                            <li>
                                Use two monitors: one for the video chat; one
                                for the presentation
                            </li>
                            <li>
                                If sharing full-screen in Powerpoint, you
                                sometimes have to share out a separate window or
                                you’ll see the Presenter’s view
                            </li>
                            <li>
                                Or have another team member run the presentation
                            </li>
                            <li>Practice ahead of your presentation</li>
                        </ul>
                    </li>

                    <li>
                        <b>Meeting Participation</b>
                        <ul>
                            <li>
                                We encourage participants to turn on their
                                camera in the private meeting rooms so that
                                other participants can see who is talking. But
                                please note that only 14 people can be on video
                                at once in the private meeting rooms.
                            </li>
                            <li>
                                Please mute yourself when not talking to avoid
                                background interruptions (barking dogs, phones
                                ringing, family members talking etc.)
                            </li>
                            <li>
                                Please review the
                                <a
                                    href="https://fmi-abc.s3.amazonaws.com/Virtual-Program-Code-of-Conduct.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    >Code of Conduct for online meetings</a
                                >.
                            </li>
                        </ul>
                    </li>

                    <li>
                        <b>Have a Meeting Plan</b>
                        <ul>
                            <li>
                                Since only 14 people at a time can be on video,
                                make a plan with your team to decide who will be
                                on video, who will speak when, who will share
                                the presentation, etc.
                            </li>
                            <li>
                                Consider coordinating your meeting agenda with
                                your trading partner. You can contact them ahead
                                of time via email or phone (if you have their
                                contact information) or you can use the
                                messaging feature in Midwinter site.
                            </li>
                        </ul>
                    </li>

                    <li>
                        <b>Prepare for the Unexpected</b>
                        <ul>
                            <li>
                                This is technology, some things *may* go wrong.
                            </li>
                            <li>
                                Have a back-up plan for if someone gets sick, if
                                someone’s internet connection drops, if a laptop
                                crashes, if someone’s audio goes haywire. Can
                                someone quickly take over the presentation?
                            </li>
                        </ul>
                    </li>
                </ul>
            </article>

            <h3>Have a Terrific Midwinter! Thank you for participating!</h3>
        </div>
        <div class="lg:w-1/4 lg:pl-8">
            <ul>
                <li>
                    <a href="#faqs">Frequently Asked Questions</a>
                    <ul>
                        <li><a href="#login">Logging Into Midwinter</a></li>
                        <li><a href="#meetings">Scheduled Meetings</a></li>
                        <li><a href="#sponsors">Sponsors</a></li>
                        <li>
                            <a href="#particpants"
                                >Communicating With Other Participants</a
                            >
                        </li>
                        <li><a href="#troubleshooting">Troubleshooting</a></li>
                    </ul>
                </li>
                <li>
                    <a href="#practices"
                        >Best Practices For Meeting Participation</a
                    >
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "midwinter"
};
</script>

<style lang="scss" scoped>
@import "../../styles/setup/colors";
@import "../../styles/views/help";
</style>
